.playsongs-home-alex {
  top: 9.2708vw;
  left: -8.9583vw;

  width: 13.5417vw;
  height: 17.9427vw;

  @media (min-width: 768px) and (max-width: 1023px) {
    top: 19.0104vw;
    left: -6.5104vw;
    width: 17.7734vw;
    height: 23.3464vw;
  }

  @media (max-width: 767px) {
    top: 33.9535vw;
    left: -11.6279vw;
    width: 31.7442vw;
    height: 41.6977vw;
  }
}

.playsongs-home-max {
  top: 4.7917vw;
  left: 15.1563vw;

  width: 18.1771vw;
  height: 22.4323vw;

  @media (min-width: 768px) and (max-width: 1023px) {
    left: 22.526vw;
    top: -18.4896vw;
    width: 25.3776vw;
    height: 31.3151vw;
  }

  @media (max-width: 767px) {
    top: -33.0233vw;
    left: 32.3256vw;
    width: 45.3256vw;
    height: 55.9302vw;
  }
}

.playsongs-brouce {
  top: -1.0417vw;
  left: -1.474vw;

  width: 4.3854vw;
  height: 4.2813vw;

  @media (max-width: 767px) {
    width: 13.2791vw;
    height: 12.9767vw;
    left: -5.907vw;
    top: -4.6512vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 9.6615vw;
    height: 9.4401vw;
    left: -3.5026vw;
    top: -4.2839vw;
  }
}

.playsongs-charlie {
  top: -1.6667vw;
  left: -3.3854vw;

  width: 5.9427vw;
  height: 6.2917vw;

  @media (max-width: 767px) {
    width: 17.6977vw;
    height: 17.7907vw;
    left: -8.3953vw;
    top: -7.5116vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 9.9089vw;
    height: 9.9609vw;
    left: -4.8047vw;
    top: -4.2839vw;
  }
}

.playsongs-tony {
  top: 0vw;
  left: -5.1042vw;

  width: 6.1771vw;
  height: 5.875vw;

  @media (max-width: 767px) {
    width: 9.6615vw;
    height: 9.4401vw;
    left: -3.5026vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 11.263vw;
    height: 11.1979vw;
    left: -4.8047vw;
    top: -4.2839vw;
  }
}

.playsongs-joy {
  top: -8.4167vw;
  left: 5.8854vw;
  width: 9.6302vw;
  height: 9.2031vw;

  @media (max-width: 767px) {
    width: 25.6512vw;
    height: 24.4884vw;
    left: 37.2093vw;
    top: -23.4884vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.362vw;
    height: 13.7109vw;
    left: 42.8776vw;
    top: -13.151vw;
  }
}

.playsongs-mystery {
  top: -6.474vw;
  left: 7.1354vw;
  width: 7.099vw;
  height: 7.3646vw;

  @media (max-width: 767px) {
    width: 17.907vw;
    height: 18.6047vw;
    left: 40.9302vw;
    top: -16.4884vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10.026vw;
    height: 10.4167vw;
    left: 44.9219vw;
    top: -9.2448vw;
  }
}

.playsongs-max {
  top: -7.5156vw;
  left: 5.5313vw;
  width: 10.6146vw;
  height: 9.4063vw;

  @media (max-width: 767px) {
    width: 30.186vw;
    height: 26.7442vw;
    left: 34.8837vw;
    top: -21.0465vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 16.901vw;
    height: 14.974vw;
    left: 41.5365vw;
    top: -11.7708vw;
  }
}

.playsongs-subtitle {
  margin-top: 4.6976vw;

  @media (max-width: 767px) {
    margin-top: 9.3023vw;
  }
}

.playsongs-rectangle1 {
  margin-top: 15.7552vw;

  @media (max-width: 767px) {
    margin-top: 24.3696vw;
  }
}

.playsongs-rectangle2 {
  margin-top: 15.7552vw;

  @media (max-width: 767px) {
    margin-top: 28.1395vw;
  }
}

.playsongs-rectangle3 {
  margin-top: 11.849vw;

  @media (max-width: 767px) {
    margin-top: 23.5116vw;
  }
}

.playsongs-rectangleview {
  height: 10vw;

  @media (max-width: 767px) {
    height: 34.186vw;
  }
}

.playsongs-curriculum-span-step-title {
  font-size: 1.0417vw;
  line-height: 1.5;
  letter-spacing: -0.0208vw;
  text-align: left;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 4.186vw;
    line-height: 1.33;
    letter-spacing: -0.0837vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.6042vw;
    letter-spacing: -0.0521vw;
  }
}

.playsongs-curriculum-span-step-title .text {
  font-size: 1.5625vw;
  font-weight: bold;
  letter-spacing: -0.0313vw;

  @media (max-width: 767px) {
    font-size: 5.5814vw;
    letter-spacing: -0.1116vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.9063vw;
    letter-spacing: -0.0781vw;
  }
}

.playsongs-curriculum-span-step-script {
  font-size: 1.5625vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.0313vw;
  text-align: left;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 4.6512vw;
    line-height: 1.75;
    letter-spacing: -0.1395vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
    line-height: 1.25;
    letter-spacing: -0.0625vw;
  }
}

.playsongs-contactus-imglist {
  margin-top: 2.0833vw;
}

.playsongs-contactus-titleicon-address {
  @media (min-width: 1024px) {
    width: 1.3542vw;
    height: 1.3542vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2.474vw;
    height: 3.125vw;
  }

  @media (max-width: 767px) {
    width: 4.4186vw;
    height: 5.5814vw;
  }
}

.playsongs-contactus-titleicon-tel {
  @media (min-width: 1024px) {
    width: 1.5625vw;
    height: 1.4583vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2.6042vw;
    height: 2.6042vw;
  }

  @media (max-width: 767px) {
    width: 4.6512vw;
    height: 4.6512vw;
  }
}

.playsongs-contactus-titleicon-time {
  @media (min-width: 1024px) {
    width: 1.5104vw;
    height: 1.4583vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2.6042vw;
    height: 2.6042vw;
  }

  @media (max-width: 767px) {
    width: 4.6512vw;
    height: 4.6512vw;
  }
}

.playsongs-contactus-titleicon-schedule {
  @media (min-width: 1024px) {
    width: 1.5104vw;
    height: 1.6667vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2.2135vw;
    height: 2.6042vw;
  }

  @media (max-width: 767px) {
    width: 3.9535vw;
    height: 4.6512vw;
  }
}

.playsongs-contactus-titleicon-park {
  @media (min-width: 1024px) {
    width: 1.0417vw;
    height: 1.4583vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 1.6927vw;
    height: 2.3438vw;
  }

  @media (max-width: 767px) {
    width: 3.0233vw;
    height: 4.186vw;
  }
}

.playsongs-contactus-selectSection {
  margin-top: 3.125vw;
}

.playsongs-familysite-logo-bicycle {
  @media (min-width: 1024px) {
    width: 16.1458vw;
    height: 3.0208vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 32.0313vw;
    height: 5.9896vw;
  }

  @media (max-width: 767px) {
    width: 40.4651vw;
    height: 7.4419vw;
  }
}

.playsongs-familysite-logo-home {
  @media (min-width: 1024px) {
    width: 5.7813vw;
    height: 5.5729vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.4531vw;
    height: 13.9323vw;
  }

  @media (max-width: 767px) {
    width: 19.3023vw;
    height: 18.8372vw;
  }
}

.playsongs-familysite-logo-insta {
  @media (min-width: 1024px) {
    margin-left: 0.625vw;
    width: 2.0833vw;
    height: 2.0833vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 1.5625vw;
    width: 5.2083vw;
    height: 5.2083vw;
  }

  @media (max-width: 767px) {
    margin-left: 1.8605vw;
    width: 9.3023vw;
    height: 9.3023vw;
  }
}

.playsongs-familysite-logo-charlie {
  @media (min-width: 1024px) {
    width: 5.625vw;
    height: 5.2604vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 14.0625vw;
    height: 13.151vw;
  }

  @media (max-width: 767px) {
    width: 20vw;
    height: 19.3023vw;
  }
}

.home-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130.2326vw;
}

.home-center-section-left {
  @media (min-width: 1024px) {
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
}

.home-center-section-right {
  @media (min-width: 1024px) {
    margin-left: 2.0833vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 22.526vw auto;
  }

  @media (max-width: 767px) {
    margin: 40.2326vw auto;
  }
}

.home-schedule {
  display: flex;
  justify-content: center;
  width: 49.2813vw;
  height: 29.1771vw;
}

.aboutus-zone {
  @media (min-width: 1024px) {
    height:42.7083vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 78.125vw;
  }

  @media (max-width: 767px) {
    height: 144.186vw;
  }
}

.contactus-zone {
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 118.2292vw;
  }

  @media (max-width: 767px) {
    height: 224.4186vw;
  }
}

.schedule-zone {
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 78.125vw;
  }

  @media (max-width: 767px) {
    height: 144.186vw;
  }
}

.mall-zone {
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 78.125vw;
  }

  @media (max-width: 767px) {
    height: 144.186vw;
  }
}

.ec-section-title {
  @media (min-width: 1024px) {
    margin-top: 3.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
  }
}

.ec-section2-description-span {
  font-weight: bold;
}
