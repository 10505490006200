.topBtn_wrap {
    position: fixed;
    bottom: 4.6875vw;
    right: 3.1250vw;
}

.topBtn {
    font-size: 3.8rem;
    color: #492d1c !important;
}

.mtopBtn_wrap {
    position: fixed;
    bottom: 6.4103vw;
    right: 12.0513vw;

}