* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url("./Fonts/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("./Fonts/woff/Pretendard-Regular.woff") format("woff");
  margin: 0;
  overflow-x: hidden;
}

.no-scroll {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.text_wrap {
  white-space: pre-wrap;
}

.path {
  width: 100%;
  height: 1.5104vw;
  font-family: Ysabeau SC;
  font-size: 1.0417vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.separatorline {
  width: 100%;
  height: 0vw;
  border: solid 0.026vw #707070;
}

.divBackground {
  background-image: url("https://playsongshome.playsongs.ai/bicycle/philosophy%403x.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
}

.fade-in {
  opacity: 0;
  transition: opacity 10s ease-in-out 1ms;
}

.fade-in.is-visible {
  opacity: 1;
}

.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.history_round {
  border-radius: 6%;
  border: solid 1px #bdbdbd;
}

.playsongs_logo {
  width: 13.2813vw;
}

.mplaysongs_logo {
  width: 14.1026vw;
}

.playsongshome_logo {
  width: 8.3594vw;
}

.mplaysongshome_logo {
  width: 11.7949vw;
}

.charlie_logo {
  width: 8.9063vw;
}

.mcharlie_logo {
  width: 11.4872vw;
}

.contactus_button {
  font-size: 1.4063vw;
  font-weight: bold;
  line-height: 1.44;
  text-align: center;
  color: #fff;
}

.mcontactus_button {
  font-size: 4.6154vw;
  font-weight: bold;
  line-height: 1.44;
  text-align: center;
  color: #fff;
}

.philsophy_top {
  padding-top: 18.2051vw;
}

.philsophy_e_top {
  padding-top: 11.7188vw;
}

.no-scroll {
  -ms-overflow-style: none;
  /* IE, Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.footer-back {

  width: 100%;
  height: 15.5469vw;
  padding: 3.5938vw 8.1250vw 2.3438vw 8.1250vw;
  background-color: #424242;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playsongs-footer-back {
  margin-top: 15.625vw;
  width: 100vw;
  /*padding: 5.0781vw 16.6667vw 4.6875vw 10.4167vw;*/

  padding: 2.0313vw 16.6667vw 1.9792vw 20.3125vw;
  background-color: #424242;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playsongs-footer-back-tablet {
   margin-top: 15.625vw;
    width: 100vw;
   
    padding: 5.0781vw 16.6667vw 4.9479vw 10.4167vw;
    background-color: #424242;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.m-footer-back {
  clear: both;

  width: 100%;
  height: 60.5128vw;
  padding: 5.1282vw 14.359vw 4.8718vw 2.5641vw;
  background-color: #424242;

  display: flex;
  align-items: center;
}

.m-footer-back-tablet {
  clear: both;

  width: 100%;
  height: 26.4323vw;
  
  background-color: #424242;

  display: flex;
  align-items: center;
  justify-content: center;
 
}

.m-playsongs-footer-back {
  clear: both;
  margin-top: 37.2093vw;
  width: 100vw;
  padding: 4.6512vw 22.093vw 4.6512vw 7.2093vw;
  background-color: #424242;

  display: flex;
  align-items: center;
}

.snp-end {
  scroll-snap-align: end;
  scroll-snap-stop: always;
}

.video_style {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.back {
  background-color: #d4ebff;
}
