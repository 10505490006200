/*body {
    padding-top: 80px;
}

.navposition {
    margin-left: 9.8438vw !important;
}
*/
.navlink-gap {
  margin-right: 2.5vw !important;
}

.navlang {
  margin-left: 4.2188vw !important;
  width: 5.4688vw !important;
}

.navlogo {
  width: 15.3125vw;
  height: 2.8125vw;
}

.navbackground {
  background-color: white;
  width: 100vw;
}

.navmenufont {
  font-family: Pretendard;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 1.19;
  text-align: left;
  color: #222;
}

.outlinemenu {
  width: 7.1795vw;
  height: 4.5385vw;
}

.nav-label-gap {
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 14.1927vw;
  }
  @media (max-width: 767px) {
    margin-top: 23.1538vw;
  }
}
